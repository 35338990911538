<!--
 * @Author: heyan
 * @Date: 2024-08-14 15:13:19
 * @LastEditors: heyan
 * @LastEditTime: 2024-08-15 18:48:45
 * @Description: 
-->
<template>
  <div class="pdd-link">
    <div v-if="!loadings" @click="jumpPage">
      <div class="main-pic-box">
        <img class="main-pic" :src="sourceData.mainPic" alt="" />
      </div>
      <!-- 金额和标题 -->
      <div class="title-price">
        <!-- 商品标题 -->
        <div class="title">
          {{ sourceData.title }}
        </div>
        <!-- 商品价格和销量 -->
        <div class="price">
          <div class="price-item">
            活动价￥<span class="actualPrice">{{ sourceData.actualPrice }}</span
            >起 <span class="pay">￥</span
            ><del class="originalPrice">{{ sourceData.originalPrice }}</del>
          </div>
        </div>
      </div>

      <!-- 商家店铺信息 -->
      <div
        v-if="sourceData.shopName || sourceData.shopLogo"
        class="business-info"
      >
        <!-- 商家logo -->
        <div class="logo" v-if="sourceData.shopLogo">
          <img class="logo-img" :src="sourceData.shopLogo" alt="" />
        </div>
        <div>{{ sourceData.shopName }}</div>
      </div>
      <!-- 淘宝按钮 -->
      <div class="tb-button" @click="jumpPage">
        <img class="img-1" src="../../assets/taobao@2x.png" alt="" />
        跳转至拼多多
        <img class="img-2" src="../../assets/taobao2.png" alt="" />
      </div>
    </div>
    <loading :tips="tips" v-else></loading>
  </div>
</template>

<script>
import aixos from "../../api/api";
import loading from "../../components/loading.vue";
export default {
  components: {
    loading,
  },
  data() {
    return {
      timer: null,
      sourceData: {},
      buyLink: "",
      callUpLink: "",
      mediaPlatform: "",
      productId: "",
      clickId: "",
      jumpSuccess: false, //跳转成功
      tips: "加载中",
      loadings: true,
      key: false,
    };
  },
  async created() {
    if(this.$route.query){
      this.mediaPlatform = this.$route.query.mediaPlatform;
      this.productId = this.$route.query.productId;
      this.clickId = this.$route.query.clickId
    }
    this.getCidLink();
    // 如果触发了这个事件就是跳转成功了
    document.addEventListener('visibilitychange', () => {
			this.jumpSuccess = true
		});

    setTimeout( () => {
      this.show = true
      this.loadings = false
    },1500 )
  },
  methods: {
    getCidLink() {
      aixos
        .post("api/page/pdd/get", {
          mediaPlatform: this.mediaPlatform,
          productId: this.productId,
          clickId: this.clickId,
          bizId: "",
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.sourceData = res.data.data.productDetail;
            this.buyLink = res.data.data.buyLink;
            this.callUpLink = res.data.data.callUpLink;
            window.location.href = this.callUpLink;
            document.title = this.sourceData.title;
            this.goodsImg =
              this.sourceData.detailPics.length > 6
                ? this.sourceData.detailPics.splice(0, 6)
                : "";
          }
        });
    },
    jumpPage() {
      if (this.key) return;
      this.key = true;

      aixos
        .post("api/page/pdd/get", {
          mediaPlatform: this.mediaPlatform,
          productId: this.productId,
          clickId: this.clickId,
          bizId: "",
        })
        .then((res) => {
          if (res.data.code == 200) {
            window.location.href = res.data.data.callUpLink;
            this.buyLink = res.data.data.buyLink;
          }
          setTimeout(() => {
            if (!this.jumpSuccess) {
              window.location.href = this.buyLink;
              this.key = false;
            }
          }, 1500);
        });
    },
  },
};
</script>

<style scoped src="./index.css"></style>