<template>
    <div class="loading">
        <div class="loading-item">
            <van-loading  color="#fff"/>
            {{tisp}}
        </div>
    </div>
</template>

<script>
export default {
    props:{
        tisp:{
            type:String,
            default:'加载中'
        }
    }
}
</script>

<style scoped>
.loading{
    min-height: 100vh;
    width: 100%;
    height: 100vh;
    max-width: 375px;
    top: 0%;
    left: 0%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}
    
.loading-item{
    width: 96px;
    height: 104px;
    background: rgba(0,0,0,0.6);
    border-radius: 12px;
    display: flex;
    color: #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}
</style>