import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import { Loading } from 'vant';
import 'vant/lib/button/style';
Vue.use(Loading);
// import VueRouter from 'vue-router'
Vue.config.productionTip = false
// Vue.use(VueRouter)
new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
