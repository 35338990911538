/*
 * @Author: heyan
 * @Date: 2024-08-14 14:35:32
 * @LastEditors: heyan
 * @LastEditTime: 2024-08-14 15:17:57
 * @Description: 
 */
import VueRouter from 'vue-router'
import Vue from 'vue'
import tb from './pages/tb/index.vue'
import jd from './pages/jd/index.vue'
import pdd from './pages/pdd/index.vue'
Vue.use(VueRouter)
// console.log(process,'123213')
const routes = [
  {
    path: '/item/tb',
    name: 'tbCid',
    component: tb
  },
  {
    path: '/item/jd',
    name: 'jd',
    component: jd
  },
  {
    path: '/',
    name: 'tbCid',
    component: tb
  },
  {
    path: '/item/pdd',
    name: 'pddCid',
    component: pdd
  },

]

const router = new VueRouter({
  mode: 'history',
  routes
})


export default router